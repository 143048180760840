@charset 'utf-8';
@import '@fortawesome/fontawesome-free/css/all.css';
@import 'react-toastify/dist/ReactToastify.css';
@import 'react-datepicker/dist/react-datepicker';
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '@saladtechnologies/garden-fonts';

html {
  overflow: auto;
}

.react-datepicker-wrapper {
  font-family: Mallory, BlinkMacSystemFont, '-apple-system', 'Work Sans', 'Segoe UI', 'Fira Sans', 'Helvetica Neue',
    Helvetica, Arial, sans-serif;
}

.react-datepicker-popper {
  z-index: 25;
}

.react-datepicker__close-icon::after {
  background-color: #1f4f22;
  font-size: 0.7rem;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #d9eca5;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #73b629;
}

.react-datepicker__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 4.7rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  margin-left: -1px;
  height: calc(195px + 0.85rem) !important;
}

.Toastify__toast-body {
  font-family: Mallory, BlinkMacSystemFont, '-apple-system', 'Work Sans', 'Segoe UI', 'Fira Sans', 'Helvetica Neue',
    Helvetica, Arial, sans-serif;
}

.Toastify__toast-icon {
  align-self: flex-start;
  padding-top: 5px;
}
